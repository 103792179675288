import React, { useEffect } from 'react';
import './about.css';

import SBA from '../../assets/static/SBA.jpg';
import West from '../../assets/static/westchester-county.png';
import Work from '../media/media';
import Awards from '../../components/awards/awards';
import Media from '../media/media';
import { /* documentToHtmlString,  */documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { getAboutMe } from '../../api/api';
const promise = getAboutMe();

function About() {

    const [about, setAbout] = React.useState<any>();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        promise.then((aboutMe: any) => {
            let aboutmecontent = aboutMe['fields']['aboutme']
            console.log("about")
            console.log(aboutmecontent)
            setAbout(aboutmecontent)
            console.log("about me!")
            console.log(aboutmecontent)

            setLoading(false)
        })
    }, [])

    return (
        <div className='about-wrapper' id="me">
            <div className='mobile-image'>

            </div>
            <div className='info' id='about'>

                <div className='about'>
                    <div className='left'>
                        <h1>About</h1>
                        {
                            loading && about !== undefined ?
                                <>
                                    <p>Over the last two decades, Jillian White has led a successful career as an executive and entrepreneur in the real estate appraisal industry. As an SRA designated appraiser, Jillian has served on The Appraisal Institute's Government Relations Committee, as Trustee of The Appraisal Foundation's Board of Trustees and as a former member of the New York State Appraisal Board.</p>
                                    <p>She began her career as founder and CEO of White Picket Fence Appraisals, a residential real estate appraisal company based in New York. Motivated by her experience as one of the industry's few black female appraisers, and having to 'whitewash' her own family’s home in order to facilitate the sale, Jillian has made it her personal mission to educate and empower all stakeholders on the topic of appraisal bias.</p>
                                    <p>Jillian received a B.A. in Neuroscience and Behavior from Columbia University.</p></>
                                :
                                documentToReactComponents(about)
                        }
                        <Awards></Awards>
                    </div>
                    <div className='right'>
                        <div className='block'>
                        </div>
                    </div>
                </div>


            </div>
            <div className='about-section'><Media></Media></div>
        </div>
    );
}


export default About;