import React from 'react';

import './awards.css';

import SBA from '../../assets/static/SBA.jpg';
import West from '../../assets/static/westchester-county.png';
import HFS from '../../assets/static/hfs.jpg';
/* import HFS from 'https://housingfinancestrategies.com/wp-content/uploads/2020/11/logo2.png'; */

function Awards() {
    return (
        <div className='awards'>
            <h1>Awards</h1>
            <div className='award'>
                <div className='award-logo'>
                    <img src={HFS} alt='HFS' />
                </div>
                <div className='award-text'>
                    <p>
                        #HousingDC2021 Hero Award presented by Housing Finance Strategies
                    </p>
                </div>
            </div>
            <div className='award'>
                <div className='award-logo'>
                    <img src={SBA} alt='SBA' />
                </div>
                <div className='award-text'>
                    <p>
                        2007 Young Entrepreneur of the Year, Small Business Administration Metro NY District
                    </p>
                </div>
            </div>
            <div className='award'>
                <div className='award-logo'>
                    <img src={West} alt='SBA' />
                </div>
                <div className='award-text'>
                    <p>
                        2010 Influential Blacks Honoree presented by African American Chamber of Commerce of Westchester
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Awards;